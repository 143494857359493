body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 90vh;
  background-color: #181818 !important; /* TODO :: add to .core package */
  margin-top: 60px !important;
}

::-webkit-scrollbar-track {
  border-radius: 1.96px;
  background-color: #323232;
}

::-webkit-scrollbar {
  width: 4px; /* height of horizontal scrollbar */
  height: 4px; /* width of vertical scrollbar */
  background-color: #323232;
}

::-webkit-scrollbar-thumb {
  border-radius: 1.96px;
  background-color: #4c4c4c;
}
